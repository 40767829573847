<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.phone }}</v-card-title>
                <v-card-text>
                    <UserTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.phone"
                                    outlined
                                    label="Телефон"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="entity.admin"
                                    label="Админ"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="entity.partner"
                                    label="Партнер"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.firstname"
                                    outlined
                                    label="Имя"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.lastname"
                                    outlined
                                    label="Фамилия"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="entity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить пользователя? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import UserTabLinks from '../../components/users/UserTabLinks';

    import { imageSrc, translit } from '../../helpers';

    export default {
        name: 'UserEdit',

        components: {
            UserTabLinks,
        },

        data() {
            return {
                imageSrc,
                entity: {
                    phone: '',
                    firstname: '',
                    lastname: '',
                    admin: false,
                    partner: false,
                    enabled: true,
                },
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('users', {
                oldEntity: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('users/get', {id});
                this.entity = this.oldEntity;
            }
        },

        methods: {
            async save() {
                try {
                    store.commit('users/SET_ENTITY', this.entity);
                    await store.dispatch('users/save');
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('users/delete', {id});
                    this.$router.push('/users');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/users/${id}/detail`);
                } else {
                    this.$router.push('/users');
                }
            },
        },
    };
</script>
